/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AppContext from "./AppContext"
// import { useEffect } from "react"

//import Header from "./header"
import "./layout.css"

import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const getCookie  = (name) => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  const submitLoginForm = e => {
    e.preventDefault();
    console.log("submitLoginForm:", e.target.email.value, e.target.password.value);
    var now = new Date();
    now.setTime(now.getTime() + 1 * 1800 * 1000); //expire in 0.5 hours
    document.cookie = "username="+e.target.email.value+";expires="+now.toGMTString()+";path=/";
    document.cookie = "CID=01197D4CCDF32CE9DD8F12817EBE79148.U1;expires="+now.toGMTString()+";path=/";
    setAuthState({
      email: e.target.email.value,
      isAuth: true,
      authMenuText: "Logout",
      submitLoginForm: submitLoginForm,
      doLogout: doLogout
    });
    // Dirty implementation: redo to trigger new event rule
      window.location.reload();
  };

  const doLogout = () => {
    document.cookie = 'username=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'CID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // Dirty implementation: redo to trigger new event rule
    window.location.reload();
  }

  const initialAuthState = { 
    email: (typeof window !== 'undefined' && getCookie('username') !== null) ? getCookie('username') : "", 
    isAuth: (typeof window !== 'undefined' && getCookie('username') !== null) ? true : false, 
    authMenuText: (typeof window !== 'undefined' && getCookie('username') !== null) ? "Logout" : "Login",
    submitLoginForm: submitLoginForm,
    doLogout: doLogout
  }

  let [authState, setAuthState] = useState({...initialAuthState});
  /*

  // Old code removed from JSX
  //<Header siteTitle={data.site.siteMetadata.title} /> 
  /*
<div
        style={{
          /*margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,* /
        }}
      >
  */

  
  return (
    <>
      <AppContext.Provider value={authState}>
        <main>{children}</main>
      </AppContext.Provider>
    </>
  )
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
